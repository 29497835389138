<template>
  <div class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          btnTitle="Add New"
          :showBtn="true"
          :showSettingBtn="false"
          :showAddNew="false"
          title="Add Workflow"
          @onClickAddNewButton="onClickAddNewButton"
          @onClickSettingButton="onClickSettingButton"
          @onClickCloseButton="onClickCloseButton"
      />

      <div class="row mt-2">
        <h4>Basic Info</h4>
        <hr/>
        <div class="col-12 col-sm-6 col-md-6">
          <div class="mb-2 row">
            <label for="colFormLabel" class="col-12 col-sm-2 col-md-3 col-xl-3 col-form-label">Workflow Type:</label>
            <div class="col-sm-10 col-md-9 col-xl-7">
              <select class="form-control" v-model="workflow_type">
                <option :value="requisition" selected>Requisition</option>
                <option :value="cs">CS</option>
                <option :value="quotation">Quotation</option>
                <option :value="po">PO</option>
                <!--                <option :value="voucherType" v-for="voucherType in Object.keys(homeData.voucher_type)" >{{ homeData.voucher_type[voucherType] }}</option>-->
              </select>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-6">
          <div class="mb-2 row">
            <label for="colFormLabel" class="col-12 col-sm-2 col-md-3 col-xl-3 col-form-label">Process Date:</label>
            <div class="col-sm-10 col-md-9 col-xl-7">
              <input
                  v-model="process_date"
                  type="date"
                  class="form-control date-mask"
                  placeholder="Process Date"
              >
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-6">
          <div class="mb-2 row">
            <label for="colFormLabel" class="col-12 col-sm-2 col-md-3 col-xl-3 col-form-label">Workflow Name:</label>
            <div class="col-sm-10 col-md-9 col-xl-7">
              <input class="form-control" type="text" v-model="workflow_name" name="workflow_name"
                     placeholder="Requisition for 100,000">
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-3 col-md-3">
          <div class="mb-2 row">
            <label for="colFormLabel" class="col-12 col-sm-2 col-md-3 col-xl-3 col-form-label">Start Date:</label>
            <div class="col-sm-10 col-md-9 col-xl-7">
              <input
                  v-model="start_date"
                  type="date"
                  class="form-control date-mask"
                  placeholder="Start Date"
              >
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-3 col-md-3">
          <div class="mb-2 row">
            <label for="colFormLabel" class="col-12 col-sm-2 col-md-3 col-xl-3 col-form-label">End Date:</label>
            <div class="col-sm-10 col-md-9 col-xl-7">
              <input
                  v-model="end_date"
                  type="date"
                  class="form-control date-mask"
                  placeholder="End Date"
              >
            </div>
          </div>
        </div>

        <!--        <div class="col-2">-->
        <!--          <label for="">&nbsp;</label>-->
        <!--          <button-->
        <!--              style="min-width: 64px;margin-top: 1rem;"-->
        <!--              @click="onClickSearchButton"-->
        <!--              class="btn btn-primary waves-effect waves-float waves-light form-control"-->
        <!--          >Go-->
        <!--          </button>-->
        <!--        </div>-->
      </div>
    </div>
  </div>

  <div class="card">
    <div class="p-2">
      <div class="row">
        <div class="col-9">
          <p class="h2 m-0">Recipients</p>
        </div>

        <div class="col-3">
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <a href="#">User Role</a>
            <a href="#">User</a>
            <a href="#">In Absence of</a>
          </div>
        </div>
      </div>
      <hr/>
      <div class="card-body">
        <div class="row d-flex flex-wrap justify-content-between align-items-center">
          <div class="col-3">
            <div class="card border-1" style="width: 18rem;">
              <div class="card-body">
                <h5 class="card-title text-center">1</h5>
                <img src="http://dev.miaccounts.net/images/avatar.jpg" class="card-img-top" alt="img">
                <p class="card-text mt-2">
                  User Name <br>
                  Designation
                </p>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="card border-1" style="width: 18rem;">
              <div class="card-body">
                <h5 class="card-title text-center">2</h5>
                <img src="http://dev.miaccounts.net/images/avatar.jpg" class="card-img-top" alt="img">
                <p class="card-text mt-2">
                  User Name <br>
                  Designation
                </p>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="card border-1" style="width: 18rem;">
              <div class="card-body">
                <h5 class="card-title text-center">3</h5>
                <img src="http://dev.miaccounts.net/images/avatar.jpg" class="card-img-top" alt="img">
                <p class="card-text mt-2">
                  User Name <br>
                  Designation
                </p>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="card border-1" style="width: 18rem;">
              <div class="card-body">
                <h5 class="card-title text-center">Final Approval</h5>
                <img src="http://dev.miaccounts.net/images/avatar.jpg" class="card-img-top" alt="img">
                <p class="card-text mt-2">
                  User Name <br>
                  Designation
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="p-2">
      <div class="row">
        <div class="col-12">
          <p class="h2 m-0">Conditions</p>
        </div>
      </div>
      <hr/>
      <div class="card-body">
        <p class="card-text">
          Total Amount over or equal <br>
          Total Amount under or equal <br>
          Equal
        </p>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="p-2">
      <div class="row">
        <div class="col-12">
          <p class="h2 m-0">Department match</p>
        </div>
      </div>
      <hr/>
      <div class="card-body">
        <div class="mb-2"></div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="p-2">
      <div class="row">
        <div class="col-12">
          <p class="h2 m-0">Supplier match</p>
        </div>
      </div>
      <hr/>
      <div class="card-body">
        <div class="mb-2"></div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="p-2">
      <div class="row">
        <div class="col-12">
          <p class="h2 m-0">Item match</p>
        </div>
      </div>
      <hr/>
      <div class="card-body">
        <p class="card-text">
          (Item Group / specific item)
        </p>
        <div class="mb-2"></div>
      </div>
    </div>
  </div>

  <div class="card">
      <div class="card-body">
        <div class="row mb-1 d-flex justify-content-end">
          <div class="col-2">
            <label for="">&nbsp;</label>
            <button
                style="min-width: 64px;margin-top: 1rem;"
                class="btn btn-secondary waves-effect waves-float waves-light form-control"
            >Cancel
            </button>
          </div>

          <div class="col-2">
            <label for="">&nbsp;</label>
            <button
                style="min-width: 64px;margin-top: 1rem;"
                @click="onClickSearchButton"
                class="btn btn-primary waves-effect waves-float waves-light form-control"
            >Save
            </button>
          </div>
        </div>
      </div>
  </div>
</template>

<style>
hr {
  margin: 1rem 0;
  color: #403d4452 !important;
  background-color: currentColor;
  border: 0;
  opacity: 1;
}

.title-background{
  background: #DBE7F2
}
</style>

<script setup>
import TitleButton from '@/components/atom/TitleButton'
import Pagination  from '@/components/atom/Pagination'
import Loader      from '@/components/atom/LoaderComponent'
import {inject}    from "vue";

function onClickAddNewButton() {
  alert('Add New Button clicked');
}
function onClickCloseButton() {
  alert('Close Button clicked');
}

function onClickSettingButton() {
  alert('Setting Button clicked');
}

function onClickSearchButton() {
  alert('Search Button clicked');
}

function onUploadLogo() {
  //
}

function onUploadCoverPhoto() {
  //
}

function getJournals() {
  //todo
}

function onPageChange() {
  //todo
}
</script>
